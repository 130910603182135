const initState = () => ({
  items: [],
  cartItemCount: 0,
})

export const state = initState

export const getters = {
}

export const mutations = {
  removeItem(state, {item}){
    let index = state.items.indexOf(item)
    if (index > -1){
      state.items.splice(index,1)
    }
  },
  resetItems(state){
    state.items = []
    state.cartItemCount = 0
  },
  addQuantity(state, {item}) {
    let index = state.items.indexOf(item)
    state.items[index].quantity++
  },
  subtractQuantity(state, {item}){
    let index = state.items.indexOf(item)
    state.items[index].quantity--
  },
  setAttributes(state, {attributes}) {
    state.attributes = attributes
  },
  updateCartItemCount(state){
    state.cartItemCount = 0
    state.items.forEach(x => state.cartItemCount += x.quantity)
  },
  loadShoppingCart(state,{shoppingCart}){
    shoppingCart.items.forEach(x => state.items.push(x))
  },
  updateItemDiscount(state,{item, discount, markupType}){
    let index = state.items.indexOf(item)
    state.items[index].itemTypeData.discount = discount
    state.items[index].itemTypeData.markupType = markupType
  },
}

export const actions = {
  async fetchItemByCode({commit,dispatch, state}, {itemCode}){
    let response
    let item = await this.$axios.$get(`/webpos/products/getbysku/${itemCode}`);
    if(item.id){
      item.actualItemId = item.id
      item.id = item.itemTypeID
      item.productImageURLs = item.imageURLs
      item.notes = item.itemNote

      let index = state.items.findIndex( x => x.itemTypeData.actualItemID === item.actualItemId);
      if (index !== -1 && state.items[index].quantity + 1 > item.totalInventoryQuantity){
        return 0;
      }

      item.attributes = await this.$axios.$get(
        `webpos/products/${item.itemTypeID}/${item.actualItemId}/customattributevalues`
      )

      dispatch('addToCart', {item})
      response = item
    }
    else{
      let item = await this.$axios.$get(`/ItemTypes/getbysku/${itemCode}`)
      if(item.id){
        dispatch('addToCart', {item})
        response = item
      } else {
        response = null
      }
    }

    return response

  },
  async FetchItemAttributes({commit}, {item}){
    return await this.$axios.$get(`/itemtypes/${item.ID}/customattributes`).then(res =>{
    })
  },
  async addToCart({commit, dispatch, state}, {item}) {

    if (item.actualItemId > 0){
      let invItem = await this.$axios.$get(`/webpos/products/${item.actualItemId}`);

      let index = state.items.findIndex( x => x.itemTypeData.actualItemID === item.actualItemId);

      if (index !== -1 && state.items[index].quantity + 1 > invItem.value.quantity){
        this.$toast.error(
          'There is insufficient quantity on inventory!'
        );
        return;
      }
    }
    const shoppingCartItem = {
      ItemTypeID: item.id,
      Quantity: item.quantity ? item.quantity : 1 ,
      Notes: item.notes ? item.notes : '',
      Code: item.code,
    }

    shoppingCartItem.ItemTypeData = {
      ItemTypeID: item.id,
      Quantity: item.quantity ? item.quantity : 1,
      Price: item.price ? item.price : 0,
      BaseCost: item.cost ? item.cost : 0,
      ImageUrl:
        item.productImageURLs.length > 0
          ? item.productImageURLs[0]
          : null,
      Code: item.code,
      Name: item.name,
      PriceType: item.priceType,
      groupID: item.groupID,
      Notes: item.notes ? item.notes : '',
      attributes: item.attributes || [],
      inventoryItemId: item.inventoryItemId ? item.inventoryItemId : item.inventoryItemID ? item.inventoryItemID : null ,
      usePriceFromCartItem: !!item.actualItemId,
      itemSKU: item.itemSKU,
      actualItemId: item.actualItemId ? item.actualItemId : null,
    }
    const addToCartResult = await this.$axios.$post(
      '/webpos/ShoppingCart/addToCart',
      shoppingCartItem
    )
    if (addToCartResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    }else{
      dispatch('getBySessionId')
    }
    return addToCartResult
  },
  async addPreparedItemToCart({commit,dispatch}, {shoppingCartItem}){
    const addToCartResult = await this.$axios.$post(
      '/webpos/ShoppingCart/addToCart',
      shoppingCartItem
    )
    if (addToCartResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    }else{
      dispatch('getBySessionId')
    }
    return addToCartResult
  },
  async getBySessionId({commit}) {
    const shoppingCart = await this.$axios.$get(
      '/webpos/shoppingCart/GetByUserId'

    )

    commit('resetItems')
    commit('loadShoppingCart',{shoppingCart})
    commit("updateCartItemCount")
  },
  async updateAddQuantity({commit,state},{item}){
    commit('addQuantity', {item})
    let index = state.items.indexOf(item)
    let quantity = state.items[index].quantity

    if (item.itemTypeData.actualItemID > 0){
      let invItem = await this.$axios.$get(`/webpos/products/${item.itemTypeData.actualItemID}`);
      if (invItem.value.quantity < quantity){
        commit('subtractQuantity', {item})
        this.$toast.error(
          'There is insufficient quantity on inventory!'
        );
        return;
      }
    }

    const updateResult = await this.$axios.$post(
      'webpos/shoppingCart/updateQuantity',
      {id: item.id, quantity: quantity}
    )
    if (updateResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    }

    commit("updateCartItemCount")
  },
  async updateSubtractQuantity({commit,state,dispatch},{item}) {
    commit('subtractQuantity', {item})
    let index = state.items.indexOf(item)

    let quantity = state.items[index].quantity
    const updateResult = await this.$axios.$post(
      'webpos/shoppingCart/updateQuantity',
      {id: item.id, quantity: quantity}
    )
    if (updateResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    }

    if (quantity <= 0){
      dispatch('deleteCartItem',{item})
    }

    commit("updateCartItemCount")
  },
  async deleteCartItem({commit},{item}){
    const updateResult = await this.$axios.$delete(
      `/webpos/shoppingCart/deleteCartItem/${item.id}`
    )
    commit('removeItem',{item})
    commit("updateCartItemCount")
  },
  async clearCart({commit}){
    const clearResult = await this.$axios.$post(
      `/webpos/shoppingCart/clear-cart`
    )
    commit('resetItems')
    commit("updateCartItemCount")
  },
  async updateDiscount({commit,state,dispatch},{item, discount, markupType}){
    commit('updateItemDiscount', {item: item,discount: discount, markupType: markupType})
    let index = state.items.indexOf(item)

    let shoppingCartItem = state.items[index]
    const updateResult = await this.$axios.$post(
      'webpos/shoppingCart/update-cart-item',
      shoppingCartItem
    )
    if (updateResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    }


  }

}
