import {mapFilters} from '@/utils/helpers';
const initState = () => ({
})

export const state = initState
export const getters = {
}
export const mutations = {}

export const actions = {
  async fetchCustomerCredits({commit},{filters,pagingInfo}){
    let resultArray = [];
    try{
      let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      let result = await this.$axios.post(`webpos/customer-credit`,filter);
      if (result.data.hasErrors){
        this.$toast.error("Error while getting customer credits");
        return resultArray
      }
      resultArray = result.data;
    }
    catch(e){
      this.$toast.error("Error while getting customer credits");
    }
    return resultArray

  },
  async getCustomerCreditItems({commit},{memoID}){
    let resultArray = [];
    try{
      let result = await this.$axios.get(`webpos/customer-credit/items-by-memo-id/${memoID}`);
      if (result.data.hasErrors){
        this.$toast.error("Error while getting customer credit items!");
      }
      else
      {
        resultArray = result.data.value;
      }
    }
    catch(e){
      this.$toast.error("Error while getting customer credit items!");
    }
    return resultArray
  },
  async getCustomerInvoicesForCustomerCredits({commit},{pagingInfo, filters}){
    let resultArray = [];
    let url = `webpos/customer-credit/invoice-items/?customerID=${filters.customerID}&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
    try{
      if(filters.storeID > 0){
        url =`${url}&storeID=${filters.storeID}`
      }
      let result = await this.$axios.get(url);
      if (result.data.hasErrors){
        this.$toast.error("Error while getting customer invoice items!");
      }
      else
      {
        resultArray =  result.data.value;
      }
    }
    catch(e){
      this.$toast.error("Error while getting customer invoice items!");
    }
    return resultArray;
  },
  async fetchItemTypeByCode({commit}, {filters}){
    let result;
    let url = `webpos/itemtypes/get-itemtype-by-SKU/?ItemSKU=${filters.itemCode}`
    try{
      if(filters.storeID > 0){
        url =`${url}&storeID=${filters.storeID}`
      }
      result = await this.$axios.$get(url)
      if(!result || result.hasErrors || !result.value){
        this.$toast.error("Item type was not found!");
        return null
      }
    }
    catch(e){
      this.$toast.error("Error while getting item type!");
    }
    return result
  },
  async createNewCustomerCredit({commit},{customerCredit}){
    try{
      const result = await this.$axios.post(`webpos/customer-credit/create-customer-credit`, customerCredit);
      if (result.data.hasErrors){
        this.$toast.error("Error while creating customer credit!");
        return;
      }
      if (result.data.value.id > 0){
        this.$toast.success("Customer credit created successfully!");
      }
      return result.data
    }
    catch(error){
      if(error.response){
        this.$toast.error(error.response.data.Message);
        return;
      }
      this.$toast.error('Error while creating new customer credit.');
    }
  }
}

