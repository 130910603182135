import {formActions} from '@/enums/formActions'

const initState = () => ({
  showFilter: false,
  product: {},
  products: [],
  customProduct:{},
  customProducts: [],
  attributes: [],
})

export const state = initState

export const getters = {

}

export const mutations = {

  setProducts(state, {products}) {
    state.products = products
  },
  setProduct(state, {product}){
    state.product = product
  },
  setCustomProducts(state, {products}) {
    state.customProducts = products
  },
  setAttributes(state, {attributes}){
    let attributesWithValues = attributes.map((attr) => {
      let valueStr = `${attr.valueStr ? attr.valueStr + " " + (attr.measureUnitSymbol || '') : '-'}`.trim()

      if (valueStr){
        return {
          id: attr.id,
          code: attr.code,
          name: attr.name,
          valueStr: valueStr,
          value: attr.value,
          file: {
            fileUrl: attr.imageUrl,
          }
      }
      }
    })

    state.attributes = attributesWithValues.filter(a => a != null)

  },

}

export const actions = {
  async getProducts({commit}, {id, pagingInfo}) {
    commit('setProducts', {products: []})
    const products = await this.$axios.$get(
      `webpos/products?categoryId=${id}`+
      `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
    )
    commit('setProducts', {products})
    return products
  },
  async getFilteredProducts({commit}, {filters, pagingInfo}){
    return new Promise((resolve, reject)=>{
      this.$catch(
        async () => {
          let url = `webpos/products?categoryId=${filters.id}`+
            `&code=`+ (filters.code ? filters.code : '')  +
            `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
          filters.appliedFilters.forEach(f =>{
            if(f.value){
              url = url + `&${f.property}=${f.value}`
            }
          })
          const filteredProducts = await this.$axios.$get(
            url
          )
            resolve({filteredProducts})

          commit('setProducts',{products: filteredProducts})
        },
        formActions.Load,
        'filteredProducts',
      )
    })

  },
  async getProductById({commit}, productId) {
    let product = {}
    try{
      const result = await this.$axios.$get(
        `webpos/products/${productId}`
      )
      if(result && !result.hasErrors){
        if(!result.value){
          this.$toast.show('Product not found');
        }
        else{
          product = result.value;
        }
      }
      else{
        this.$toast.error('Error while getting product');
      }
    }
    catch {
      this.$toast.show('Product not found');
    }
    return product;


  },
  async fetchProductAttributes({commit}, {itemTypeID,entityID, showInProductCatalog}) {
    var attributes = await this.$axios.$get(
      `webpos/products/${itemTypeID}/${entityID}/customattributevalues`
    )
    if (showInProductCatalog) {
        attributes = attributes.filter(attr => attr.showInProductCatalog)
    }
    return attributes.map((attr) => {
      let valueStr = `${attr.valueStr ? attr.valueStr + " " + (attr.measureUnitSymbol || '') : '-'}`.trim()
      return {
        id: attr.id,
        code: attr.code,
        name: attr.name,
        valueStr: valueStr,
        value: attr.value,
        file: {
          fileUrl: attr.imageUrl,
        }
      }
    });
  },
  async getCustomizableProducts({commit}, {id, pagingInfo}) {
    commit('setCustomProducts', {products: []})
    const products = await this.$axios.$get(
      `webpos/products/customize?categoryId=${id}`+
      `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
    )
    commit('setCustomProducts', {products})
    return products
  },
  async getFilteredCustomizableProducts({commit}, {filters, pagingInfo}){
    return new Promise((resolve, reject)=>{
      this.$catch(
        async () => {
          let url = `webpos/products/customize?categoryId=${filters.id}`+
            `&code=`+ (filters.code ? filters.code : '')  +
            `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`

          filters.appliedFilters.forEach(f =>{
            if(f.value){
              url = url + `&${f.property}=${f.value}`
            }
          })

          const filteredProducts = await this.$axios.$get(
            url
          )
          resolve({filteredProducts})

          commit('setCustomProducts',{products: filteredProducts})
        },
        formActions.Load,
        'filteredProducts',
      )
    })

  },

  async getQuantityByInventoryLocation({commit},{itemTypeID}){
    try{
        const result = await this.$axios.$get(`webpos/products/get-quantity-by-inventory-location/${itemTypeID}`);
        if(result && !result.hasErrors) {
          if (!result) {
            this.$toast.show('Error while getting quantities by inventory locations');
          } else {
            return result;
          }
        }
    }catch(err){
      console.log("Error while getting quantities by inventory locations: ", err)
      this.$toast.error("Error while getting quantities at inventory locations")
    }
  }
}
