import {formActions} from '@/enums/formActions'

const initState = () => ({
  customer: {},
  customerSettings: {},
})

export const state = initState

export const mutations = {
  setCustomerDatas(state, {customer}) {
    if(customer){
      state.customer = customer;
    }
    else{
      state.customer = {}
    }
  },
  unselectCustomer(state) {
    state.customer = {}
  },
  incCustomerWishlistItemCount(state){
    state.customer.wishlistItemCount += 1
  },
  descCustomerWishlistItemCount(state){
    state.customer.wishlistItemCount -= 1
  },
  setCustomerSettings(state,{customerSettings}){
    state.customerSettings = customerSettings
  },
  setCustomerCurrencySettings(state, {customer}){
    state.customer.customerCurrencyIsDifferentFromSystemCurrency = customer.customerCurrencyIsDifferentFromSystemCurrency
    state.customer.exchangeRate = customer.exchangeRate
    state.customer.currencySymbol = customer.currencySymbol
    state.customer.exchangeDate = customer.exchangeDate
  },
  setCustomerHasSameShipping(state, {value}){
    this.state.customer.hasSameShippingInfo = value;
  },
}

export const actions = {
  async updateCustomer({commit}, {customer}) {
    await this.$catch(
      async () => {
        await this.$axios.$put(`webpos/customer/updateCustomerContactDatas/${customer.id}`, {
          id: customer.id,
          customerUSID:  customer.usID,
          companyName: customer.customerIsCompany ? customer.firstName : null,
          firstName:  customer.customerIsCompany ? null : customer.firstName,
          lastName: customer.customerIsCompany ? null : customer.lastName,
          contactFirstName: customer.customerIsCompany ? customer.contactFirstName : null,
          contactLastName: customer.customerIsCompany ? customer.contactFirstName : null,
          email: customer.email,
          phone: customer.phone,
          contactFullName: (customer.contactFirstName !== null && customer.contactFirstName !== "") ?`${customer.contactFirstName} ${customer.contactLastName}` : null,
          addressID: customer.addressID,
          currencyId: customer.currencyID,
          address: {
            id: customer.addressID || null,
            addressLine1: customer.address1,
            addressLine2: customer.address2,
            countryID: customer.countryID,
            stateID: customer.stateID,
            city: customer.city,
            zip: customer.zip
          },
          hasSameShippingInfo: customer.hasSameShippingInfo,
        }).then((result) => {
          if(!result.hasErrors){
            this.$toast.success("Customer updated successfully");
          }
          else{
            this.$toast.error(result.errorMessages[0].value);
          }

        }).catch((result) => {
          this.$toast.error(result.message);
        });
      },
      formActions.Save
    );
  },

  saveCustomer({commit, dispatch}, customerId){
    this.$catch(
      async () => {
        let result = await this.$axios.$get(`webpos/customer/getCustomerContactData/${customerId}`, {
          id: customerId,
        });

        if (!result.hasErrors) {
          let apiCustomer = await dispatch('convertCustomer', {customer: result.value, address: result.value.address})
          apiCustomer.wishlistItemCount = result.value.wishlistItemCount > 0 ? result.value.wishlistItemCount : 0
          commit('setCustomerDatas', {customer: apiCustomer});
        } else {
          this.$toast.error('Error while selecting the customer');
        }
      });
  },

  async insertNotes({commit}, {customerId, notes}){
    const result = await this.$axios.$post(`webpos/customer/insert-notes/${customerId}`, notes)
    if (result && !result.hasErrors){
      this.$toast.success("Customer notes inserted successfully");
    }
    else {
      this.$toast.error('Error while inserting notes for customer');
    }
  },
  async insertEvent({dispatch}, {event, customerId}){
    const convertedEvent = await dispatch('convertEvent', {event: event, customerId: customerId});
    const result = await this.$axios.$post(`webpos/customer-event/insert-event`, convertedEvent);
    if (result && !result.hasErrors) {
      if(event.id){
        this.$toast.success("Event updated successfully!");
      }
      else{
        this.$toast.success("Event inserted successfully!");
      }
    }
    else {
      if(event.id){
        this.$toast.error('Error while updating event!');
      }
      else{
        this.$toast.error('Error while inserting event!');
      }
    }
  },
  async getCustomerEventByCustomerId({commit}, {customerId, pagingInfo}){
    const filter = {
      customerId: customerId,
      pageNo: pagingInfo.pageNo,
      pageSize: pagingInfo.pageSize,
      sortBy: pagingInfo.sortBy,
      sortAsc: pagingInfo.sortAsc
    }
    let result = await this.$axios.post(`webpos/customer-event/by-customer`, filter);
    if (result && !result.data.hasErrors) {
      return result.data;
    } else {
      this.$toast.error("Error while getting customer events");
      return null;
    }
  },
  async deleteEvent({commit}, {eventId}){
    const result = await this.$axios.$delete(`webpos/customer-event/${eventId}`)
    if(result && !result.hasErrors){
      this.$toast.success("Event deleted successfully!");
    }
    else{
      this.$toast.error("Error while deleting event!");
    }
  },
  async getCustomerContactData({commit, dispatch}, customerId){
    let result = await this.$axios.$get(`webpos/customer/getCustomerContactData/${customerId}`)
    if(result && !result.hasErrors) {
      commit('setCustomerCurrencySettings', {customer: result.value});
      return await dispatch('convertCustomer', {customer: result.value, address: result.value.address })
    }
    else {
      this.$toast.error('Error while selecting the customer!');
      return null;
    }
  },
  async getCustomerSettings({commit}){
    let result = await this.$axios.$get(`webpos/customer/get-customer-settings`)
    if (result.hasErrors){
      this.$toast.error("Error while getting customer settings");
    }
    else
    {
      commit('setCustomerSettings', {customerSettings: result.value});
    }
  },
  async convertCustomer({commit}, {customer, address}){
    return {
      id: customer.id,
      usID: customer.customerUSID,
      name: customer.companyName || `${customer.firstName} ${customer.lastName}`,
      firstName:  customer.companyName || customer.firstName,
      lastName: customer.lastName || '',
      email: customer.email,
      phone:  customer.phone,
      contactFirstName: (customer.companyName && customer.contactFullName.split(' ').length > 1) ? customer.contactFullName.split(' ')[0] : '',
      contactLastName:  (customer.companyName && customer.contactFullName.split(' ').length > 1) ? customer.contactFullName.split(' ')[1] : '',
      addressID: customer.address && customer.addressID || '',
      address: address && address.addressName || '',
      address1: address && address.addressLine1 || '',
      address2: address && address.addressLine2 || '',
      countryID: address && address.countryID || '',
      stateID: address && address.stateID || '',
      country: address && address.country || '',
      city: address && address.city || '',
      state: address && address.state || '',
      zip: address && address.zip || '',
      wishlistItemCount: 0,
      companyName: customer.companyName,
      customerCurrencyIsDifferentFromSystemCurrency: customer.customerCurrencyIsDifferentFromSystemCurrency,
      currencySymbol: customer.currencySymbol,
      exchangeRate: customer.exchangeRate,
      exchangeDate: customer.exchangeDate,
      currencyID: customer.currencyId || 0,
      hasSameShippingInfo: customer.hasSameShippingInfo,
      customerTypeID: customer.customerTypeID,
    }
  },
  async convertEvent({},{event, customerId}){
    if(event){
      return {
        customerId: customerId,
        name: event.name,
        relatedCustomerId: event.relatedCustomer && event.relatedCustomer.value || null,
        relationship: event.relationship.value,
        eventType: event.eventType.value,
        isRecurring: event.isRecurring,
        month: event.month.value,
        day: event.day,
        year: event.year || null,
        includeInReport: event.includeInReport,
        id: event.id || null
      }
    }
  },
  async getCustomerShippingAddressesByCustomerId({commit}, {customerId, pagingInfo}){
    const filter = {
      customerId: customerId,
      pageNo: pagingInfo.pageNo,
      pageSize: pagingInfo.pageSize,
      sortBy: pagingInfo.sortBy,
      sortAsc: pagingInfo.sortAsc
    }
    let result = await this.$axios.post(`webpos/customer/shipping-addresses-by-customer`, filter);
    if (result && !result.data.hasErrors) {
      return result.data;
    } else {
      this.$toast.error("Error while getting customer shipping addresses");
      return null;
    }
  },
  async insertCustomerShipping({dispatch}, {address, customerId}){
    address.customerId = customerId;
    try {
      const result = await this.$axios.$post(`webpos/customer/save-shipping-addresses`, address);
      if (result && !result.apiMessages.hasErrors) {
        if(address.id){
          this.$toast.success("Shipping address updated successfully!");
        }
        else{
          this.$toast.success("Shipping address inserted successfully!");
        }
      }
      else {

        this.$toast.error(result.apiMessages.serverErrors[0]);
      }
    }
    catch (ex) {

      this.$toast.error(ex);
    }

  },
  async deleteShippingAddress({commit}, {address}){
    try {
      const result = await this.$axios.$post(`webpos/customer/delete-shipping-address`, address);
      if(result && !result.apiMessages.hasErrors){
        this.$toast.success("Shipping address deleted successfully!");
      }
      else{
        this.$toast.error("Error while deleting shipping address!");
      }
    }catch (ex){
      this.$toast.error(ex);
    }

  },

  async checkCustomerIsActive({commit},customerID){
    try{
      return await this.$axios.$get(`webpos/customer/${customerID}/check-customer-is-active`);
    }catch(ex){
      this.$toast.error(ex);
    }
  }

}
