import {mapFilters} from "@/utils/helpers";
import * as url from "url";

const initState = () => ({
})

export const state = initState
export const actions= {

  async fetchShippingMethods({commit, state}){
    const url = `/webpos/shipping-method/`;
    let shippingMethods = [];
    try {
      const result = await this.$axios.$get(url);
      const filteredResult = result.map(({id, name, code}) => ({value: id, text: name, code: code}));
      if (result) {
        shippingMethods = filteredResult;
      } else {
        this.$toast.error('Error while getting shipping methods.');
      }
      return shippingMethods;
    }
    catch(error){
      this.$toast.error('Error while getting shipping methods.');
    }
  }

}
