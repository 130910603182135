const initState = () => ({

})

export const state = initState

export const getters = {

}

export const mutations = {

}

export const actions = {
  async getSalesRepsForFiltering({commit}) {
    const response = await this.$axios.$get('/User/salesreps')
    if(response && response.length > 0){
      return response.map((salesRep) => {
        return {
          text: salesRep.displayName,
          value: salesRep.id
        }
      })
    }
    else{
      this.$toast.error('No sales reps found!')
    }
  }

}
